import React from 'react'
import ReactDOM from 'react-dom'

import reportWebVitals from './reportWebVitals'
import { Router } from './routes'

import './styles/base/index.scss'
import 'react-slideshow-image/dist/styles.css'
import DAppProvider from './providers/dapp'
import { AppStoreProvider } from 'hooks/context'

ReactDOM.render(
    <React.StrictMode>
        <DAppProvider>
            <AppStoreProvider>
                <Router />
            </AppStoreProvider>
        </DAppProvider>
    </React.StrictMode>,
    document.getElementById("root")
)

reportWebVitals()
