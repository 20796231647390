export const ip = "https://us-central1-tokenine-nft-khonkaen.cloudfunctions.net"

function response(json: any) {
      return json;
}

export const GET = (path: string) => {
      return fetch(ip + path, {
            method: "GET"
      })
            .then(response => response.json())
            .then(response)
};

export const POST = (path: string, formData: FormData) => {
      return fetch(ip + path, {
            method: "POST",
            body: formData
      })
            .then((res) => res.json())
            .then(response);
}

