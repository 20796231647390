import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Home, Market, Detail, CreateWork, Profile, EditProfile, AddWorkStep1, CreateBid, PlaceBid } from 'containers';
import { Footer, Navbar } from 'components';
import RequestToBeCreator from 'containers/user/request-to-be-creator';

import { DebugSmartContract } from 'debug/smartcontract'

export default function Router() {

     const debugPages =  (process.env.NODE_ENV === "development") ? [
          { path: '/debug/smartcontract', component: DebugSmartContract },
     ] : []

     const pages = [
          { path: '/', component: Home },
          { path: '/home', component: Home },
          { path: '/nft-market', component: Market },
          { path: '/detail/:id?', component: Detail },
          { path: '/profile/:creator?', component: Profile },
          { path: '/profile/edit/:creator?', component: EditProfile },
          { path: '/create-work', component: CreateWork },
          { path: '/user/add-work-step1', component: AddWorkStep1 },
          { path: '/creator/set-auction/:id', component: CreateBid },
          { path: '/user-placebid/:id', component: PlaceBid },
          { path: '/request-to-be-creator', component: RequestToBeCreator },
          ...debugPages
     ]

     return (
          <BrowserRouter>
               <div className="min-h-screen">
                    <div className="custom:height-full-screen flex items-stretch flex-col">
                         {/* {!location.pathname.startsWith("/profile") && */}
                         <Navbar id="default" />
                         {/* } */}
                         <Switch>
                              {pages?.map((el) => (
                                   <Route exact path={el.path} component={el.component} key={el.path} />
                              ))}
                         </Switch>
                         <Footer />
                    </div>
               </div>
          </BrowserRouter>
     )
}
