import { useState, useEffect, useCallback, ChangeEvent } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Countdown from 'react-countdown'
import { BigNumber } from 'ethers'

import { useDAppContext } from 'providers/dapp'
import { useEthersContext } from 'providers/ethers'
import { LoadingScreen } from 'components/Loader'
import { ConnectButton } from 'components/Wallet'
import { AddressMask } from 'components/AddressMask';

import { bidPrice } from 'modules/bid'
import { RiAuctionFill } from 'react-icons/ri'
import { FaHandPointUp, FaHandPaper, FaCheck } from 'react-icons/fa'
import { firestore } from 'libs/firebase-sdk'

import { TextField } from 'components/index'
import { DividerProps } from '@material-ui/core'

import { safeAddress } from 'utils/contract'
import Debug, { $debug } from 'utils/debug'

import { FallbackWorkIsNotApproved } from "components/Fallbacks"

type Props = {
    data?: any
    workID?: string
}

enum Panel {
    Main = "main",
    Buy = "buy",
    Bid = "bid"
}

export default function ItemDetail({ data: parentData, workID }: Props) {
    const { account, chain } = useDAppContext()
    const { contracts } = useEthersContext()

    const { tokenID: artworkTokenId, isReadyToSell: _isReadyToSell } = parentData

    const [ isLoading, setIsLoading ] = useState(true)
    const [ result, setResult ] = useState<any>(null)
    const [ error, setError ] = useState<any>(null)

    const currencies = {
        "0xae13d989dac2f0debff460ac112a837c89baa7cd": {
            symbol: "WBNB",
            address: "0xae13d989dac2f0debff460ac112a837c89baa7cd",
            type: "BEP20",
            chainId: 97,
            network: "bsc-testnet"
        },
        "0x1df0672a4553090573ffc1260f4e9fca8575d366": {
            symbol: "JFIN-T",
            address: "0x1df0672a4553090573ffc1260f4e9fca8575d366",
            type: "BEP20",
            chainId: 97,
            network: "bsc-testnet"
        }
    }

    const defaultCurrency = {
        symbol: "JFIN",
        address: "0x1df0672a4553090573ffc1260f4e9fca8575d366"
    }

    const [ currency, setCurrency ] = useState("BNB")
    const [ panel, setPanel ] = useState<Panel>(Panel.Main)
    const [ approvedCurrencyTokens, setApprovedCurrencyTokens] = useState({})
    const [ isApprovedForAll, setIsApprovedForAll ] = useState(false)
    const [ isSellingPopupActive, setSellingPopupActive ] = useState(false)
    const [ isAuctionStarted, setIsAuctionStarted ] = useState(false)
    const [ artworkTokenOwner, setArtworkTokenOwner ] = useState("")
    const [ artworkTokenMinter, setArtworkTokenMinter ] = useState("")
    const [ isReadyToSell, setReadyToSell ] = useState(false)
    
    const [ isUpdate, setIsUpdate] = useState(false);
    const forceUpdate = useCallback(() => setIsUpdate(true),[])

    const [ price, setPrice ] = useState(0)
    const [ currentPrice, setCurrentPrice ] = useState("0")
    const [ currencyToken, setCurrencyToken ] = useState(defaultCurrency)
    
    // const resolvedCurrencyAddress = currencies && (currencies as any)[currencyToken.symbol]


    // This function is for checking that user is approve all ERC-721 tokens.
    const checkApprovalForAll = async () => {
        console.log("checkApprovalForAll", account, contracts.NFTMARKET)
        if (account && contracts.NFTMARKET.address) {
            const _result = await contracts.NFTWORK.isApprovedForAll(account, contracts.NFTMARKET.address)
            
            setIsApprovedForAll(_result)
        }
    }

    const checkOwnerOfNFT = async (_artworkTokenId: string) => {
        try {
            const _result = await contracts.NFTWORK.minterOf(_artworkTokenId)
            if (safeAddress(_result) === safeAddress(account)) {
                return true
            }
            setArtworkTokenOwner(_result)

            return false
        } catch (error) {
            console.error(error)
            return false
        }
    }

    const checkCurrentPrice = async (_artworkTokenId: string) => {
        try {
            // console.log(contracts.NFTMARKET.address)
            const _result = await contracts.NFTMARKET.getCurrentPrice(_artworkTokenId)
            console.log("checkCurrentPrice", _result)
            const _price = _result.toString()
            setCurrentPrice(_price)
        } catch (error) {
            console.error(error)
        }
    }

    const checkAllowanceForCurrencyToken = async (_currencyToken: any) => {
        // @ts-ignore
        const debug = new Debug("checkAllowanceForCurrencyToken")
        // console.log(debug)
        debug.info("account", account)
        debug.object("contracts.NFTMARKET", contracts.NFTMARKET)
    
        try {
            const currencyTokenContract = contracts[chain.code][safeAddress(_currencyToken.address)]

            if (!account) throw { internal: true, data: { message: "Account is not available" }}
            if (!_currencyToken.address) throw { internal: true, data: { message: "Invalid currency token address" }}
            if (!currencyTokenContract) throw { internal: true, data: { message: "Currency token contract not found" }}
            if (!currencyTokenContract.allowance) throw { internal: true, data: { message: "Currency token contract not support allowance checking" }}
            if (!contracts.NFTMARKET) throw { internal: true, data: { message: "Invalid NFT bidding contract" }}
            
            const _result = await currencyTokenContract.allowance(account, contracts.NFTMARKET.address)
            $debug.info(`Allowance amount of ${_currencyToken.symbol} (${_currencyToken.address}) to NFT Market Contract (${contracts.NFTMARKET.address}):`, _result.toString())
            
            const _approvedAmount = _result.toNumber()
            const _approvedCurrencyTokens = approvedCurrencyTokens;
            (_approvedCurrencyTokens as any)[_currencyToken.address] = _approvedAmount
            setApprovedCurrencyTokens(_approvedCurrencyTokens)
            $debug.info("checkAllowanceForCurrencyToken", approvedCurrencyTokens)
        } catch (error) {
            if (error.internal) {
                console.error("checkAllowanceForCurrencyToken Error:", error.data.message)
            }
        }
    }

    const checkReadyToSell = async () => {
        try {
            if (!contracts.NFTMARKET) {
                throw {}
            } else {
                // const _result = checkOwnerOfNFT(artworkTokenId)
                console.log("checkReadyToSell")
                // setReadyToSell(_result)
                const _askedByUser = await contracts.NFTMARKET.getAsksByUser(account)
                console.log("getAsksByUser", _askedByUser)
                // if (_askedByUser[0] && _askedByUser[0].price.toString() !== "0") {
                //     setReadyToSell(true)
                // } else {
                //     setReadyToSell(false)
                // }
                const _askedLength = await contracts.NFTMARKET.getAsksLength()
                console.log("getAsksLength", _askedLength)
            }
            const _owner = await contracts.NFTWORK.ownerOf(artworkTokenId)
            const _minter = await contracts.NFTWORK.minterOf(artworkTokenId)
            setArtworkTokenMinter(_minter)
            if (safeAddress(_owner) === safeAddress(contracts.NFTMARKET.address)) {
                setReadyToSell(true)
            } else { 
                setReadyToSell(false)
            }
        } catch (e) {

        }
    }

    const toggleSetSellingPricePopup = (flag = false) => {
        setSellingPopupActive(flag)
    }

    useEffect(() => {
        setIsLoading(false)
        handleChangeCurrency(null, defaultCurrency.address)
    }, [])

    // Check Contract Approval
    useEffect(() => {
        (async () => {
            if (safeAddress(account) === safeAddress(artworkTokenOwner)) { await checkApprovalForAll() }
            await checkReadyToSell()
            await checkCurrentPrice(artworkTokenId)
            await checkAllowanceForCurrencyToken(currencyToken)
        })()
    }, [ account, contracts.NFTMARKET, artworkTokenOwner, isLoading ])


    const getArtworkOwner = async (artworkTokenId: number) => {
        console.log("WORK Contract", contracts.NFTWORK, contracts)
        try {
            if (!artworkTokenId) { throw { message: "Invalid Artwork Token Id" } }
            if (!contracts.NFTWORK) { throw { message: "Invalid NFT Work contract" } }
            // const _result = await contracts.NFTWORK.ownerOf(artworkTokenId)
            const _owner = await contracts.NFTWORK.ownerOf(artworkTokenId)
            const _minter = await contracts.NFTWORK.minterOf(artworkTokenId)
            console.log("getArtworkOwner", _owner, "minter", _minter)
            setArtworkTokenOwner(_owner)
            setArtworkTokenMinter(_minter)
            return { owner: _owner, minter: _minter }
        } catch (error) {
            console.log("getArtworkOwner", error)
            setResult(null)
            setError(error)
        }
    }

    useEffect(() => {
        (async () => {
            console.log("getArtworkTokenOwner")
            await getArtworkOwner(artworkTokenId)
        })()
    }, [ artworkTokenId, contracts.NFTWORK ] )

    const handleChangeCurrency = (event: ChangeEvent<HTMLSelectElement> | null, _currencyTokenAddress: any) => {
        // console.log("handleChangeCurrency", event || _currencyTokenAddress )
        setCurrencyToken((currencies as any)[event ? event.target.value : _currencyTokenAddress])
    }

    const handleOnChangePriceInput = ({ target }: any) => {
        if (target.value > 0) { setPrice(target.value) }
    }

    const handleApprovalForAllResult = (txResult: any) => {
        if (txResult) {
            setIsLoading(false)
        }
    }

    // useEffect(() => {
        
    // }, [])

    const $d = { 
        ...parentData,
        chain,
        account,
        contracts,
        error,
        result,
        price,
        currentPrice,
        currencyToken,
        currencies,
        artworkTokenOwner
    }

    const $h = {
        setError, setResult, setPanel, setPrice, forceUpdate, setIsLoading,
        handleChangeCurrency,
        handleOnChangePriceInput,
        toggleSetSellingPricePopup,
        handleApprovalForAllResult
    }

    return (
        <>
            <LoadingScreen isLoading={isLoading} />

            <div id="container-detail" className="item-container">
                <div className="item-wrapper">
                    <div className="item-wrapper-content">
                        {
                            panel === Panel.Main
                            ? (<>
                                <div id="gallery-detail" className="gallery-container" >
                                    <div className="gallery-wrapper">
                                        <figure className="gallery-wrapper-content">
                                            <img src={$d?.resource.origin} alt="" />
                                        </figure>
                                    </div>
                                </div>

                                <div className="item-content">
                                    <div className="__group-top">
                                        <h1>
                                            {$d?.name}
                                        </h1>
                                        <CurrentPriceDisplay data={$d} />
                                        {
                                            // 
                                            (account && (safeAddress(artworkTokenOwner) === safeAddress(account)))
                                            ? <h5 className="ownership-display">Owned by you</h5>
                                            : safeAddress(artworkTokenMinter) === safeAddress(artworkTokenOwner)
                                                ? <h5 className="text-black">Minted and Owned by: <AddressMask address={artworkTokenOwner} /></h5>
                                                : (safeAddress(artworkTokenOwner) === safeAddress(contracts.NFTMARKET.address)
                                                    ? (<>
                                                        <h5 className="text-black">Minted by: <AddressMask address={artworkTokenMinter} /></h5>
                                                        <h5 className="text-black">Ready to buy</h5>
                                                    </>)
                                                    : (<>
                                                        <h5 className="text-black">Minted by: <AddressMask address={artworkTokenMinter} /></h5>
                                                        <h5 className="text-black">Owned by: <AddressMask address={artworkTokenOwner} /></h5>
                                                    </>)
                                                )
                                        }
                                        <CreatorMiniDisplay />
                                    </div>
                                    <div className="__group-bottom">
                                        <div className="__actions-panel">
                                            <div id="button-detail" className="button-group detail">
                                                <div className="button-group-content">
                                                { 
                                                    // Check if account is ready
                                                    account
                                                        // Check if this account is owner of this work
                                                        ? safeAddress(account) === safeAddress(artworkTokenOwner)
                                                            ? $d.isApproved
                                                                // Check if user is approved smart contract (the Bidding contract for this case)
                                                                ? isApprovedForAll
                                                                        
                                                                    // Check if this work is set to ready to sell
                                                                    ? isReadyToSell
                                                                        // Show panels for User to control selling
                                                                        ? (<div className="seller-actions">
                                                                            <div className="price-input">
                                                                                <PriceInputPanel data={$d} handlers={$h} />
                                                                                <UpdatePriceButton data={$d} handlers={$h} />
                                                                            </div>
                                                                            {/* <SetAuctionButton data={$d} /> */}
                                                                            <CancelSellTokenButton data={$d} handlers={$h} />
                                                                        </div>)
    
                                                                        // Show panels for User to setup selling
                                                                        : (<div className="seller-actions">
                                                                            <SellSetupPanel data={$d} handlers={$h} />
                                                                        </div>)
                                                                    
                                                                // Show requires button for user to approve smart contract to prevent further error
                                                                : <ApproveForAllButton data={$d} handlers={$h} />
                                                            : (<>
                                                                <FallbackWorkIsNotApproved />
                                                            </>)

                                                        // If user is not a seller 
                                                        : (approvedCurrencyTokens as any)[currencyToken.address] > 0
                                                        
                                                            // Check if user was is approve the smart contract (ERC-20 Token for this case)
                                                            ? (<>
                                                                {/* <PriceInputPanel data={$d} handlers={$h} /> */}
                                                                <BuyNFTTokenButton data={$d} handlers={$h} />
                                                                {/* <button
                                                                    className="btn-default __icon"
                                                                    onClick={() => setPanel(Panel.Bid)}
                                                                >
                                                                    <FaCheck />
                                                                    <span className="ml-2">Bid your price</span>
                                                                </button> */}
                                                                {/* <PlaceBidButton id={workID} /> */}
                                                            </>)
                                                            : (<>
                                                                <ApproveCurrencyButton data={$d} handlers={$h} />
                                                            </>)

                                                        : (<>
                                                            <ConnectButton />
                                                        </>)
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        { isAuctionStarted && (<CountdownTimerDisplay data={$d} />)}

                                        {/* <span className="detail-around">≈ $540.0490</span>
                                        <span className="detail-amount">
                                            Amount：<b>1</b>
                                        </span> */}
                                        
                                    </div>
                                </div>
                            </>)
                            : panel === Panel.Bid 
                            //
                            ? (<>
                                <BidPanel data={$d} handlers={$h} />
                            </>)
                            // 
                            : (<>
                                <div>Error</div>
                                
                            </>)
                        }
                    </div>
                </div>
            </div>
            {
                isSellingPopupActive && <SetSellingPricePopup data={$d} handlers={$h} />
            }
        </>
    )
}

const SellSetupPanel = ({ data, handlers }: any) => {
    return (<div>
        <PriceInputPanel data={data} handlers={handlers} />
        <SetReadyToSellTokenButton data={data} handlers={handlers} />
        {/* <CancelSellTokenButton data={data} handlers={handlers} /> */}
    </div>)
}

/* 

*/

const BidPanel = ({ data, handlers }: any) => {
    const { account, contracts, tokenID: NFTTokenId, currencies } = data
    const { setPanel } = handlers
    const { artWork: NFT, bid: BID } = contracts
    const [ bidHistory, setBidHistory ] = useState([])

    // Get Latest Bid Entry of
    const getBids = async () => {
        try {
            const _result = await BID.getBids(NFTTokenId)
            const _length = await BID.getBidsLength(NFTTokenId)
            const _user = await BID.getUserBids(account)
            console.log("getBids", _result, _length, _user)
            return _result
        } catch (error) {
            console.error("getBids", error)
        }
    }

    const handleBidHistory = (bidsEntry: any) => {
        setBidHistory(bidsEntry)
    }

    useEffect(() => {
        (async () => {
            const _result: any = await getBids()
            if (_result && _result.length > 0) {
                handleBidHistory(_result)
            }
        })()
    }, [])

    // useEffect(() => {
    //     try {
    //         // contracts.bid.filters["Ask"](NFT.address)
    //         BID.on("*", (event: any) => {
                
    //             console.log("On Bid Event", event)
    //         })

    //     } catch (error) {
    //         console.error(error)
    //     }
    //     return BID.off("*")
    // })

    return (
    <div key="bid-panel" id="bid-panel">
        <div className="bid-history-pane">
            <h5>Bid Entry</h5>
            <div>
                {
                    bidHistory.length > 0
                    ? bidHistory.map((item: any, index: number) => {
                        const bidder = item.bidder
                        const price = item.price.toString()
                        const tokenAddress = item.quoteTokenAddr.toLowerCase()
                        const currency = currencies[tokenAddress]
                        const symbol = currency ? currency.symbol : "Unknown" 

                        return (<div className="bid-entry" key={index}>
                            <span className="_address"><AddressMask address={bidder} /></span> 
                            <span className="_price">{ price }</span> 
                            <span className="_symbol" data-token-address={tokenAddress}>{ symbol }</span>
                        </div>)
                    })
                    : (<h3>No one has bid yet.</h3>)
                }
            </div>
        </div>

        <div className="bid-info">
            <div id="gallery-detail" className="gallery-container" >
                <div className="gallery-wrapper">
                    <figure className="gallery-wrapper-content">
                        <img src={data.resource.origin} alt="" />
                    </figure>
                </div>
            </div>

            <div className="_actions">
                <PriceInputPanel data={data} handlers={handlers} />
                <BidNFTTokenButton data={data} handlers={handlers} />
                <UpdateBidNFTTokenButton data={data} handlers={handlers} />
                
                <button
                    className="btn-default __icon"
                    onClick={() => setPanel(Panel.Main)}
                >
                    <FaCheck />
                    <span className="ml-2">Close</span>
                </button>
            </div>
        </div> 
        
    </div>)
}

/* 

*/
const CurrentPriceDisplay = ({ data, handlers }: any) => {
    const { currentPrice, currencyToken } = data

    return (<div className="current-price">
        { currentPrice } { currencyToken.symbol}
    </div>)
}

/* 

*/
const PriceInputPanel = ({ data, handlers }: any) => {
    
    const { price, currencyToken, currencies } = data
    const { handleOnChangePriceInput, handleChangeCurrency } = handlers

    return (<>
        <div className="price-input-panel">
            <input
                onChange={handleOnChangePriceInput}
                required
                placeholder={`${price}`}
                type="number"
                min={1}
            />

            <select
                id="formGridState"
                value={currencyToken.address}
                onChange={handleChangeCurrency}
            >
                {
                    Object.keys(currencies).map(k => <option key={k} value={k}>{ `${currencies[k].symbol}`}</option>)
                }
            </select>
        </div>
    </>)
}

/* 

*/
const ApproveForAllButton = ({ data, handlers }: any) => {
    const { account, contracts } = useEthersContext()
    const __data = { account, contracts }

    return (
        <>
            <button
                className="btn-default __icon"
                onClick={() => handleSetApprovalForAll(__data, handlers)}
            >
                <FaCheck />
                <span className="ml-2">Approve to Sell</span>
            </button>
            <span>You must approve before you can sell your work</span>
        </>
    )
}

/* 

*/
const handleSetApprovalForAll = async (data: any, handlers: any) => {

    const { account, contracts } = data
    const { NFTWORK, NFTMARKET } = contracts
    
    const { setError, setResult, setIsLoading, handleApprovalForAllResult } = handlers

    try {
        if (!account) { throw { message: "Account is not available to create a new work" } }
        if (!NFTWORK) { throw { message: "Artwork contract not available" } }
        if (!NFTMARKET) { throw { message: "Bid contract not available" } }
        setIsLoading(true)

        const resultTx = await NFTWORK.setApprovalForAll(NFTMARKET.address, true)

        resultTx.wait()
        .then(async (r: any) => {
            handleApprovalForAllResult(resultTx)
            setResult(resultTx, r)
            setIsLoading(false)
        })
        .catch((e: any) => {
            setIsLoading(false)
            setError(e)
        })
        
    } catch (error) {
        setResult(null)
        setError(error)
    }
}

/* 

*/
const ApproveCurrencyButton = ({ data, handlers }: any) => {

    return (
        <>
            <button
                className="btn-default __icon"
                onClick={() => handleApproveCurrencyToken(data, handlers)}
            >
                <FaCheck />
                <span className="ml-2">Approve {data.currencyToken.symbol}</span>
            </button>
            <span>You must approve before you can sell your work</span>
        </>
    )
}

/* 
 
*/
const handleApproveCurrencyToken = async (data: any, handlers: any) => {

    const { account, contracts, chain, currencies, currencyToken, price } = data
    const { setError, setResult, forceUpdate, setIsLoading } = handlers

    const { NFTWORK, NFTMARKET, artWork: ARTWORK, bid: BID } = contracts
    
    const tokenSmartContract = contracts[chain.code][safeAddress(currencyToken.address)]

    try {
        if (!account) { throw { message: "Account is not available to create a new work" } }
        if (!currencyToken) { throw { message: `Invalid selected currency token => ${currencyToken}` } }
        if (!tokenSmartContract) { throw { message: `Invalid currency token smart contract object => ${tokenSmartContract}` }}
        setIsLoading(true)

        const _price = BigNumber.from("0xfffffffffffff")

        const resultTx = await tokenSmartContract.approve(
            NFTMARKET.address,
            _price,
        )

        resultTx.wait()
        .then(async (r: any) => {
            setResult(resultTx, r)
            setIsLoading(false)
        })
        .catch((e: any) => {
            setIsLoading(false)
            setError(e)
        })

    } catch (error) {
        console.log(error)
        setResult(null)
        setError(error)
    }
}


/* 
 
*/

const SetReadyToSellTokenButton = ({ data, handlers }: any) => {
    const { account = "0x0", contracts } = useEthersContext()

    const data__ = {
        ...data,
        account,
        contracts
    }

    return (
        <button
            className="btn-default __icon"
            onClick={() => handleSetReadyToSell(data__, handlers)}
        >
            <RiAuctionFill />
            <span className="ml-2">{`Set Work for Sell`}</span>
        </button>
    )
}


/*
*/
export const handleSetReadyToSell = async (data?: any, handlers?: any) => {    
    const { account, contracts, tokenID: artworkTokenId, artworkTokenOwner, price, currencyToken } = data

    const { setError, setResult, setIsLoading } = handlers
    const { NFTWORK, NFTMARKET } = contracts

    try {
        if (!account) { throw { internal: true, data: { code: -1, message: "Account is not available to create a new work" } } }
        if (!NFTWORK) { throw { internal: true, data: { code: -1,  message: "Artwork contract not available" } } }
        if (!NFTMARKET) { throw { internal: true, data: { code: -1, message: "Bid contract not available" } } }
        if (!artworkTokenId) {throw { internal: true, data: { code: -1, message: "Invalid Artwork Token Id" } } }
        if ((artworkTokenOwner).toLowerCase() !== (account).toLowerCase()) { throw { internal: true, data: { code: -1,  message: "Artwork is not own by this account" } } }
        if (!price) { throw { internal: true, data: { code: -1,  message: "Invalid price" } } }
        if (!currencyToken) { throw { internal: true, data: { code: -1,  message: "Invalid currency token" } } }
        setIsLoading(true)

        const resultTx = await NFTMARKET.readyToSellToken(
            artworkTokenId,
            price,
            currencyToken.address
        )

        // TODO: Improve success result and transaction display.
        // alert(`Setup Sell Successfully \n with transaction hash: \n${resultTx.hash} \nYou may need to wait a little bit to see result`)
        // console.info("handleSetReadyToSell", resultTx)
        
        resultTx.wait()
        .then(async (r: any) => {
            setResult(resultTx, r)
            // alert(`Setup Sell Transaction successfully`)
            await NFTMARKET.getCurrentPrice(
                artworkTokenId
            )
            setIsLoading(false)
        })
        .catch((e: any) => {
            // console.error(`Setup Sell Transaction failed`, e)
            setIsLoading(false)
            setError(e)
        })

    } catch (error) {
        if (error.internal) {
            alert(error.data.message)
        }
        if (error.data && error.data.code === 3) {
            if (error.data.message === "") {
                alert()
            }
            if (error.data.message === "execution reverted: Only Token Owner can sell token") {
                // alert("You're not a seller, maybe you've already setup sell, now you can only change sell price or cancel it.")
            }
        }
        console.log(error)

        setResult(null)
        setError(error)
        setIsLoading(false)
    }
}

const CancelSellTokenButton = ({ data, handlers }: any) => {
    const { account, contracts } = useEthersContext()
    const { tokenID: artworkTokenId } = data

    const data__ = {
        ...data,
        account,
        contracts
    }

    return (
        <button
            className="btn-default __icon"
            onClick={() => handleCancelSelling(data__, handlers)}
        >
            <RiAuctionFill />
            <span >{`Cancel Sell`}</span>
        </button>
    )
}

export const handleCancelSelling = async (data?: any, handlers?: any) => {
    const { account, contracts, tokenID: artworkTokenId } = data
    const { setError, setResult, setIsLoading } = handlers

    const { NFTMARKET } = contracts

    try {
        if (!account) { throw { internal: true, data: { code: -1, message: "Account is not available to create a new work" } } }
        if (!NFTMARKET) { throw { internal: true, data: { code: -1, message: "Bid contract not available" } } }
        if (!artworkTokenId) { throw { internal: true, data: { code: -1, message: "Invalid Artwork Token Id" } } }
        // if ((artworkTokenOwner).toLowerCase() !== (account).toLowerCase()) { throw { message: "Artwork is not own by this account" } }
        setIsLoading(true)

        const resultTx = await NFTMARKET.cancelSellToken(
            artworkTokenId
        )

        // TODO: Improve success result and transaction display.
        // alert(`Cancel sell successfully \n with transaction hash: \n${resultTx.hash} \nYou may need to wait a little bit to see result`)
        
        resultTx.wait()
        .then((r: any) => {
            setResult({ resultTx, r })
            // alert(`Cancel Sell Transaction successfully`)
            setIsLoading(false)
        })
        .catch((e: any) => {
            // console.error(`Cancel Sell Transaction failed`, e)
            setIsLoading(false)
            setError(e)
        })
    } catch (error) {
        if (error.internal) {
            alert(error.data.message)
        }
        if (error.data && error.data.code === 3) {
            if (error.data.message === "execution reverted: Only Seller can cancel sell token") {
                alert("To cancel sell, you must be a seller and setup sell first.")
            }
        }
        console.log(error)
        
        setResult(null)
        setError(error)
        setIsLoading(false)
    }
}


const UpdatePriceButton = ({ data, handlers }: any) => {
    const { account = "0x0", contracts } = useEthersContext()

    const data__ = {
        ...data,
        account,
        contracts
    }

    // const onClick = async () => {
    //     toggleSetSellingPricePopup(true)
    // }

    return (
        <button
            className="btn-default __icon"
            onClick={() => handleUpdateSellPrice(data__, handlers)}
        >
            <RiAuctionFill />
            <span >{`Change Selling Price`}</span>
        </button>
    )
}


/*
*/
export const handleUpdateSellPrice = async (data?: any, handlers?: any) => {    
    const { account, contracts, tokenID: artworkTokenId, artworkTokenOwner, price, currencyToken } = data

    const { setError, setResult, setIsLoading, setCurrentPrice } = handlers
    const { NFTWORK, NFTMARKET } = contracts

    try {
        if (!account) { throw { internal: true, data: { code: -1, message: "Account is not available to create a new work" } } }
        if (!NFTWORK) { throw { internal: true, data: { code: -1,  message: "Artwork contract not available" } } }
        if (!NFTMARKET) { throw { internal: true, data: { code: -1, message: "Bid contract not available" } } }
        if (!artworkTokenId) {throw { internal: true, data: { code: -1, message: "Invalid Artwork Token Id" } } }
        if ((artworkTokenOwner).toLowerCase() !== (account).toLowerCase()) { throw { internal: true, data: { code: -1,  message: "Artwork is not own by this account" } } }
        if (!price) { throw { internal: true, data: { code: -1,  message: "Invalid price" } } }
        if (!currencyToken) { throw { internal: true, data: { code: -1,  message: "Invalid currency token" } } }
        setIsLoading(true)

        const resultTx = await NFTMARKET.setCurrentPrice(
            artworkTokenId,
            price,
            currencyToken.address
        )

        // TODO: Improve success result and transaction display.
        // alert(`Set currenct price successfully \n with transaction hash: \n${resultTx.hash} \nYou may need to wait a little bit to see result`)
        // console.info("handleSetReadyToSell", resultTx)
        
        resultTx.wait()
        .then(async (r: any) => {
            setResult({ resultTx, r })
            // alert(`Set currenct price transaction successfully`)
            console.log(r)
            await NFTMARKET.getCurrentPrice(
                artworkTokenId
            )
            setIsLoading(false)
        })
        .catch((e: any) => {
            // console.error(`Set currenct price transaction failed`, e)
            setIsLoading(false)
            setError(e)
        })
    } catch (error) {
        if (error.internal) {
            alert(error.data.message)
        }
        if (error.data && error.data.code === 3) {
            if (error.data.message === "") {
                alert()
            }
            if (error.data.message === "execution reverted: Only Token Owner can sell token") {
                alert("You're not a seller, maybe you've already setup sell, now you can only change sell price or cancel it.")
            }
        }
        console.log(error)

        setResult(null)
        setError(error)
        setIsLoading(false)
    }
}


const SetAuctionButton = ({ id }: any) => {
    const history = useHistory()

    const onClick = async () => {
        history.push(`/creator/set-auction/${id}`)
    }

    return (
        <button
            className="btn-default __icon"
            onClick={onClick}
        >
            <RiAuctionFill />
            <span>{`Set Auction`}</span>
        </button>
    )
}

const BuyNFTTokenButton = ({ data, handlers }: any) => {
    // const { account, contracts } = useEthersContext()
    const { currencyToken} = data
    // const price = 100000000000000

    return (
        <button
            className="btn-default __icon"
            onClick={() => handleBuyNFTToken(data, handlers)}
        >
            <FaHandPointUp />
            <span>{`Buy with ${currencyToken.symbol}`}</span>
        </button>
    )
}

const handleBuyNFTToken = async (data?: any, handlers?: any) => {
    const { account, contracts, tokenID: NFTTokenId, artworkTokenOwner, price, currencyToken } = data
    const { NFTWORK, NFTMARKET } = contracts

    // console.log("handleBuyNFTToken", account, NFTTokenId, artworkTokenOwner, price)

    // console.log("handleBidNFT", contracts)
    console.log(NFTTokenId)

    const { setError, setResult, setIsLoading } = handlers

    try {
        if (!account) { throw { message: "Account is not available to create a new work" } }
        if (!NFTWORK) { throw { message: "Artwork contract not available" } }
        if (!NFTMARKET) { throw { message: "Bid contract not available" } }
        if (!NFTTokenId) { throw { message: "Invalid NFT Token Id" } }
        if ((artworkTokenOwner).toLowerCase() === (account).toLowerCase()) { throw { message: "Artwork is own by this account" } }
        // if (!price) { throw { message: "Invalid price" } }
        if (!currencyToken) { throw { message: "Invalid currency token" } }

        const resultTx = await NFTMARKET.buyToken(
            NFTTokenId
        )

        setIsLoading(true)

        // alert(`Buy NFT Token successfully \n with transaction hash: \n${resultTx.hash} \nYou may need to wait a little bit to see result`)
        
        resultTx.wait()
        .then(async (r: any) => {
            setResult({ resultTx, r })
            // alert(`Set currenct price transaction successfully`)
            console.log(r)
            // await NFTMARKET.getCurrentPrice(
            //     artworkTokenId
            // )
            setIsLoading(false)
        })
        .catch((e: any) => {
            // console.error(`Set currenct price transaction failed`, e)
            setIsLoading(false)
            setError(e)
        })
        
    } catch (error) {
        console.log(error)
        const { data } = error
        if (data && data.code === 3) {
            if (data.message === "execution reverted: ERC20: transfer amount exceeds balance") {
                alert(`Insufficienct balance amount of ${currencyToken.symbol} to buy.`)
            }
            if (data.message === "execution reverted: Token not in sell book") {
                alert(`This NFT is not ready for sell.`)
            }
        } 
        setResult(null)
        setError(error)
    }
}


const BidNFTTokenButton = ({ data, handlers }: any) => {
    // const { account, contracts } = useEthersContext()
    const { currencyToken } = data
    // const price = 100000000000000

    return (
        <button
            className="btn-default __icon"
            onClick={() => handleBidNFTToken(data, handlers)}
        >
            <FaHandPointUp />
            <span>{`Bid with ${currencyToken.symbol}`}</span>
        </button>
    )
}

const handleBidNFTToken = async (data?: any, handlers?: any) => {
    const { account, contracts, tokenID: NFTTokenId, artworkTokenOwner, price, currencyToken } = data
    const { artWork: ARTWORK, bid: BID } = contracts

    console.log("handleBidNFTToken", account, NFTTokenId, artworkTokenOwner, price)

    const { setError, setResult } = handlers

    try {
        if (!account) { throw { message: "Account is not available to create a new work" } }
        if (!ARTWORK) { throw { message: "Artwork contract not available" } }
        if (!BID) { throw { message: "Bid contract not available" } }
        if (!NFTTokenId) { throw { message: "Invalid NFT Token Id" } }
        if ((artworkTokenOwner).toLowerCase() === (account).toLowerCase()) { throw { message: "Artwork is own by this account" } }
        if (!price) { throw { message: "Invalid price" } }
        if (!currencyToken) { throw { message: "Invalid currency token" } }

        const resultTx = await BID.bidToken(
            NFTTokenId,
            price
        )
        
        alert(`Bid NFT Token successfully at ${price} ${currencyToken.symbol} \n with transaction hash: \n${resultTx.hash} \nYou may need to wait a little bit to see result`)
        console.info(resultTx)

        setError(null)
        setResult(resultTx)
    } catch (error) {
        console.log(error)
        const { data } = error
        if (data && data.code === 3) {
            if (data.message === "execution reverted: ERC20: transfer amount exceeds balance") {
                alert(`Insufficienct balance amount of ${currencyToken.symbol} to buy.`)
            }
            if (data.message === "execution reverted: Token not in sell book") {
                alert(`This NFT is not ready for sell.`)
            }
        } 
        setResult(null)
        setError(error)
    }
}


const UpdateBidNFTTokenButton = ({ data, handlers }: any) => {

    return (
        <button
            className="btn-default __icon"
            onClick={() => handleUpdateBidNFTToken(data, handlers)}
        >
            <FaHandPointUp />
            <span>{`Update Your Price`}</span>
        </button>
    )
}

const handleUpdateBidNFTToken = async (data?: any, handlers?: any) => {
    const { account, contracts, tokenID: NFTTokenId, artworkTokenOwner, price, currencyToken } = data
    const { artWork: ARTWORK, bid: BID } = contracts

    const { setError, setResult } = handlers

    try {
        if (!account) { throw { message: "Account is not available to create a new work" } }
        // if (!ARTWORK) { throw { message: "Artwork contract not available" } }
        if (!BID) { throw { message: "Bid contract not available" } }
        if (!NFTTokenId) { throw { message: "Invalid Artwork Token Id" } }
        // if ((artworkTokenOwner).toLowerCase() !== (account).toLowerCase()) { throw { message: "Artwork is not own by this account" } }
        // if (!price) { throw { message: "Invalid price" } }
        // if (!currencyToken) { throw { message: "Invalid currency token" } }

        // if ((artworkTokenOwner).toLowerCase() !== (account).toLowerCase()) {
        //     resultTxApprove = await ARTWORK.approve(
        //         BID.address, artworkTokenId
        //     )
        // }

        const resultTx = await BID.updateBidPrice(
            NFTTokenId,
            price,
        )

        setError(null)
        setResult(resultTx)
    } catch (error) {
        setResult(null)
        setError(error)
    }
}


const PlaceBidButton = ({ id }: any) => {
    const history = useHistory()

    const onClick = async () => {
        history.push(`/user-placebid/${id}`)
    }

    return (
        <button
            className="btn-default __icon"
            onClick={onClick}
        >
            <FaHandPaper />
            <span>{`Place a bid`}</span>
        </button>
    )
}


/*  

*/
export const handleBidToken = async (data?: any, handlers?: any) => {
    const { account, contracts, artworkTokenId, artworkTokenOwner, price, currencyToken } = data
    const { artWork: ARTWORK, bid: BID } = contracts
    const { setError, setResult } = handlers

    try {
        if (!account) { throw { message: "Account is not available to create a new work" } }
        // if (!ARTWORK) { throw { message: "Artwork contract not available" } }
        if (!BID) { throw { message: "Bid contract not available" } }
        if (!artworkTokenId) { throw { message: "Invalid Artwork Token Id" } }
        // if ((artworkTokenOwner).toLowerCase() !== (account).toLowerCase()) { throw { message: "Artwork is not own by this account" } }
        // if (!price) { throw { message: "Invalid price" } }
        // if (!currencyToken) { throw { message: "Invalid currency token" } }

        const resultTx = await BID.bidToken(
            artworkTokenId,
            price
        )

        alert(`Bid NFT Token at ${price} ${currencyToken.symbol} successfully \n with transaction hash: \n${resultTx.hash} \nYou may need to wait a little bit to see result`)
        console.info(resultTx)

        setError(null)
        setResult(resultTx)
    } catch (error) {
        setResult(null)
        setError(error)
    }
}

const CreatorMiniDisplay = () => {
    return (
        <div id="creator-mini" className="creator-container">
            <h3 className='text __txt-art'>
                Creator
            </h3>
            <Link to="/profile/xxxxxxxxx" className="creator-wrapper">
                <div className="creator-content">
                    <div className="creator-profile">
                        <img src="https://vignette2.wikia.nocookie.net/doraemon/images/c/c0/Doraemon_%282002%29.png/revision/latest?cb=20170327161129&path-prefix=en" alt="" />
                    </div>
                    <div className="creator-detail">
                        <span className="text __txt-name">Homesawan Umansap</span>
                        <span className="text __txt-at">@Homesawan</span>
                    </div>
                </div>
            </Link>
        </div>
    )
}

const CountdownTimerDisplay = ({ data }: any) => {

    const handleAuctionEnding = () => {
        console.log('Auction Ending!')
    }

    const rendererCountdown = ({ hours, minutes, seconds, completed }: any) => {
        return (
            completed
                ? (<div>Ending</div>)
                : (
                    <div id="countdown-item-detail" className="countdown-timer-content">
                        <div className="countdown-timer-column">
                            <div className="value">{hours}</div>
                            <div className="unit">Hours</div>
                        </div>
                        <div className="countdown-timer-column">
                            <div className="value">{minutes}</div>
                            <div className="unit">Minutes</div>
                        </div>
                        <div className="countdown-timer-column">
                            <div className="value">{seconds}</div>
                            <div className="unit">Seconds</div>
                        </div>
                    </div>
                )
        )
    }

    return (
        <Countdown
            date={Date.now() + 50000}
            renderer={rendererCountdown}
            onComplete={handleAuctionEnding}
        />
    )
}

const SetSellingPricePopup = (props: any) => {
    const { data, toggleSetSellingPricePopup } = props
    const { account, contracts } = useEthersContext()
    const { id, tokenID: artworkTokenId } = data
    const [price, setPrice] = useState(0)

    const currencyToken = "0x28322B766217c2364F21d28B0c2cdB38E47ABeb8"

    const el = {
        label: "Price",
        name: "New Price",
        id: "selling-price-setting-popup",
        placeholder: "0",
        type: "number"
    }

    const onChangePriceInput = ({ target }: any) => {
        if (target.value > 0) { setPrice(target.value) }
    }

    const onClickClose = () => {
        toggleSetSellingPricePopup(false)
    }

    const onClickSave = async () => {
        try {
            const result = await contracts.bid.setCurrentPrice(
                artworkTokenId,
                price.toString(),
                currencyToken,
            )
            toggleSetSellingPricePopup(false)
        } catch (e) {
            console.log(e)
        }

        try {
            const updateResult = await firestore.collection("works").doc(id).update({
                price
            })
            console.log(updateResult)

        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="interaction-popup selling-popup">

            <div className="_container">
                Set Selling Price
                <TextField label={el.label} onChange={onChangePriceInput} name={el.name} id={el.name} placeholder={el.placeholder} type={el.type} min="0" />

                <div className="group-btn">
                    <button
                        className="btn"
                        onClick={onClickSave}
                    >
                        <FaHandPaper />
                        <span className="ml-2">{`Set New Price`}</span>
                    </button>

                    <button
                        className="btn"
                        onClick={onClickClose}
                    >
                        <FaHandPaper />
                        <span className="ml-2">{`Close`}</span>
                    </button>
                </div>
            </div>
        </div>
    )
}
