import { Link } from 'react-router-dom';

import { useDAppContext } from 'providers/dapp';

import { ConnectButton } from 'components/Wallet';

import JNFT from 'assets/img/JNFT.logo.svg';
import JFNT_LOGO_WHITE from 'assets/img/JNFT.logo.white.svg';
import { firestore } from 'libs/firebase-sdk';
import { useState, useEffect, useLayoutEffect } from 'react';
import { FaUserCircle, FaUserClock, FaArrowRight, FaPlus, FaImages } from 'react-icons/fa';
import { ICreator } from '../../types'
import SideBar from 'components/side-bar/side-bar';

type navBarProps = {
     id?: string;
     profile?: JSX.Element[] | JSX.Element;
     feeds?: JSX.Element[] | JSX.Element;
};


/* TODO: Move to be a display utility */
const balanceReducer = (balance: number): string => {
     return balance.toString();
};

const Navbar = ({ id, profile, feeds }: navBarProps) => {
     const { account, disconnect } = useDAppContext();
     const symbol = 'BNB';

     const balance = 0; // Use Ethers Context
     const _balance = balanceReducer(balance);

     const [user, setUser] = useState<ICreator | null>(null)

     useEffect(() => {
          if (account) getUser()
          return () => {
               setUser(null)
          }
     }, [account])

     function useWindowSize() {
          const [size, setSize] = useState([0, 0]);
          useLayoutEffect(() => {
               function updateSize() {
                    setSize([window.innerWidth, window.innerHeight])
               }
               window.addEventListener('resize', updateSize)
               updateSize()
          }, [])
          return size
     }

     const getUser = () => {
          console.log(account)
          firestore.collection('users').doc(account || '').get().then((doc) => {
               if (doc.exists) {
                    console.log("Document data:", doc.data());
                    setUser(doc.data() as ICreator)
               } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
               }
          }).catch((error) => {
               console.log("Error getting document:", error);
          });
     }

     const CreatorButton = () => {
          const [ width, height ] = useWindowSize()
          return (
               (account)
                    ? user?.isApprovedCreator
                         ? (
                              <div className="rounded-md">
                                   {
                                        width > 576 &&
                                        <Link
                                             to="/create-work"
                                             className="custom:button-gradient rounded-xl inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-xl text-white bg-indigo-600 hover:bg-indigo-700"
                                             style={{ padding: "12px" }}
                                        >
                                             Create
                                        </Link>
                                   }
                              </div>
                         ) : (
                              <div className="rounded-md" >
                                   {
                                        width > 576 &&
                                        <Link
                                             to="/request-to-be-creator"
                                             className="custom:button-gradient rounded-xl inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-xl text-white bg-indigo-600 hover:bg-indigo-700"
                                             style={{ padding: "12px" }}
                                        >
                                             Be a Creator
                                        </Link>
                                   }
                              </div>
                         )
                    : (<></>)
          )
     }

     const openSidebar = () => {
          const side = document.querySelector<HTMLElement>('.sidebar-container')!;
          side.style.left = '0';
     }

     const [width, height] = useWindowSize()

     return (
          <>
               <div id="navbar" className="navbar-container">
                    <header id="header">
                         <nav role="navigation">
                              <div className="navbar-wrapper">
                                   <div className="navbar-content">
                                        <div className="__menu-content">
                                             {
                                                  width <= 576 &&
                                                  <button
                                                       type="button"
                                                       aria-controls="mobile-menu"
                                                       aria-expanded="false"
                                                       onClick={openSidebar}
                                                  >
                                                       <span>Open main menu</span>
                                                       {/* Icon when menu is closed.
                                                              Heroicon name: outline/menu
                                                              Menu open: "hidden", Menu closed: "block" */}
                                                       <svg
                                                            className="block"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke="currentColor"
                                                            aria-hidden="true"
                                                       >
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                                                       </svg>
                                                       {/* Icon when menu is open.
                                                              Heroicon name: outline/x
                                                              Menu open: "block", Menu closed: "hidden" */}


                                                       <svg
                                                            className="hidden"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke="currentColor"
                                                            aria-hidden="true"
                                                       >
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                       </svg>
                                                  </button>
                                             }
                                        </div>
                                        <div className="__nav-content">
                                             <div id="nav-items-logo" className="nav-items __logo">
                                                  <Link to="/home">
                                                       <img src={JFNT_LOGO_WHITE} alt="Workflow" />
                                                  </Link>
                                             </div>
                                             <div id="nav-items-item" className="nav-items __item">
                                                  <div className="space">
                                                       <nav role="navigation">
                                                            <Link to="" className="hide">
                                                                 Home
                                                            </Link>
                                                            {
                                                                 width > 576 &&

                                                                 <Link to="/nft-market" className="show">
                                                                      Marketplace
                                                                 </Link>
                                                            }
                                                       </nav>
                                                  </div>
                                             </div>
                                        </div>

                                        <div className="btn flex">
                                             <CreatorButton />
                                             <div id="nav-button-profile" className="nav-button __profile-btn">
                                                  {account ?
                                                       (
                                                            <div className="__profile-btn-wrapper">
                                                                 <div className="dropdown">
                                                                      <Link to={`/profile/${account}`}>
                                                                           <span className="__profile-btn-content">
                                                                                <div className="__text">
                                                                                     <span className="__balance">{`${_balance} ${symbol}`}</span>
                                                                                     <span className="text-xs text-gray-500">
                                                                                          {
                                                                                               user?.name
                                                                                                    ? user.name
                                                                                                    : account.substr(0, 6) + '...' + account.substr(account.length - 4, 4)
                                                                                          }
                                                                                     </span>
                                                                                </div>
                                                                                <span id="nav-profile" className="__image">
                                                                                     {
                                                                                          user?.images.thumbnail_128
                                                                                               ? (<img
                                                                                                    src={user?.images.thumbnail_128}
                                                                                                    alt=""
                                                                                                    className="ml-2 w-10 h-10 rounded-full"
                                                                                               />)
                                                                                               : (<FaUserCircle className="ml-2 w-10 h-10 rounded-full" />)

                                                                                     }
                                                                                </span>
                                                                           </span>
                                                                      </Link>
                                                                      <div className="dropdown-content">
                                                                           <div className="dropdown-content-hover">
                                                                                <Link className="dropdown-content-button" to={`/profile/edit/${account}`}>
                                                                                     Edit Profile
                                                                                </Link>
                                                                           </div>
                                                                           <div className="dropdown-content-hover">
                                                                                <button className="dropdown-content-button" onClick={disconnect}>
                                                                                     Disconnect Wallet
                                                                                </button>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       )
                                                       :
                                                       (
                                                            <ConnectButton />
                                                       )}
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </nav>
                    </header>
                    {profile}
               </div>
               {feeds}
               <SideBar
                    account={account}
                    isApprovedCreator={user?.isApprovedCreator}
               />
          </>
     );
};

export default Navbar;
