import { Link } from 'react-router-dom';
import { Label, Loading } from 'components';
import { IWork } from 'types';

type itemFeedsProps = {
     labelTitle?: string;
     labelLinkname?: string;
     works?: IWork[] | []
};

export default function ItemFeeds(props: itemFeedsProps) {
     const { labelTitle = '', labelLinkname, works } = props;
     const pageid = labelTitle.replace(' ', '').toLowerCase();

     const items = works || []

     return (
          <div id="feeds">
               <div className="body-container">
                    <div className="body-wrapper">
                         <Label title={`${labelTitle}`} linkName={labelLinkname ? labelLinkname : ''} />
                         <Loading text="loading..." status={items[0] ? false : true} padding={true}>
                              <div id="present-art-items" className="body-wrapper-content">
                                   {items?.map((el: any) => (
                                        <div id={`${pageid}-${el.id}`} key={`${pageid}-${el.id}`} className="body-content">
                                             <Link to={`/detail/${el.id}`}>
                                                  <div className="cover-art">
                                                       <figure>
                                                            <img src={el.resource.thumbnail_256} alt="" />
                                                       </figure>
                                                  </div>
                                                  <div className="title-art">
                                                       <h3>
                                                            {el.name}
                                                       </h3>
                                                       <span className="price-art">
                                                            <span >PRICE</span>
                                                            {el.price} BNB
                                                  </span>
                                                  </div>
                                             </Link>
                                        </div>
                                   ))}
                              </div>
                         </Loading>

                    </div>
               </div>
          </div>
     );
}
