import * as firebase from "firebase/app";
import "firebase/functions";
import "firebase/firestore";
// import clientSDK from '../../.private/firebase-client-sdk.json'
import clientSDK from './firebase-client-sdk.json'

const app = firebase.default.initializeApp(clientSDK)

// app.functions().useEmulator('localhost', 5001);
// app.firestore().useEmulator('localhost', 8080);

export const functions = app.functions()
export const firestore = app.firestore()