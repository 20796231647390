import React, { useEffect, useState } from 'react';
import { ItemShow, ItemFeeds, ItemArtist } from 'components'
import { ICreator, IWork } from 'types';
import { firestore } from 'libs/firebase-sdk';


export default function Home() {

     const [heroes, setHeroes] = useState<IWork[]>([])
     const [recentWorks, setRecentWorks] = useState<IWork[]>([])
     const [selectedArtist, setSelectedArtist] = useState<ICreator[]>([])

     useEffect(() => {
          (async () => {
               const list = await getWorkIndex("heroes") as any
               const _works: IWork[] | undefined[] = []
               for (let key of list) {
                    const work = await getWorkItem(key)
                    // @ts-ignore 
                    if (work) { _works.push(work) }
               }
               // @ts-ignore 
               setHeroes(_works)
               console.log('hero: ', _works)
          })()
          return () => setHeroes([])
     }, [])

     useEffect(() => {
          (async () => {
               const list = await getWorkIndex("recent") as any
               const _works: IWork[] | undefined[] = []
               for (let key of list) {
                    const work = await getWorkItem(key)
                    // @ts-ignore 
                    if (work) { _works.push(work) }
               }
               // @ts-ignore 
               setRecentWorks(_works)
               console.log('recent: ', _works)
          })()
          return () => setRecentWorks([])
     }, [])

     useEffect(() => {
          (async () => {
               const list = await getSelectedCreatorIndex() as any
               const _creators: ICreator[] | undefined[] = []
               for (let key of list) {
                    const work = await getSelectedCreatorItem(key)
                    // @ts-ignore 
                    if (work) { _creators.push(work) }
               }
               // @ts-ignore 
               setSelectedArtist(_creators)
               console.log('_creators: ', _creators)
          })()
          return () => setSelectedArtist([])
     }, [])

     const getSelectedCreatorIndex = async () => {
          try {
               const doc = await firestore.collection('users_index').doc('selected-creator').get()
               const index = doc.data()
               if (doc.exists) {
                    const list = (index as any).list
                    const sortedList = Object.keys(list).sort((a: any, b: any) => a.nanoseconds - b.nanoseconds)
                    return sortedList
               }
               return []
          } catch (error) {
               console.error("getSelectedCreatorIndex", error)
          }
     }

     const getSelectedCreatorItem = async (id: string) => {
          try {
               const doc = await firestore.collection("users").doc(id).get()
               const creator = doc.data()
               if (doc.exists) {
                    return creator
               }
               return
          } catch (e) {
               console.error("getSelectedCreatorItem", e)
          }
     }

     const getWorkIndex = async (indexName = "recent") => {
          try {
               const doc = await firestore.collection("works_index").doc(indexName).get()
               const index = doc.data()
               if (doc.exists) {
                    const list = (index as any).list
                    const sortedList = Object.keys(list).sort((a: any, b: any) => a.nanoseconds - b.nanoseconds)
                    return sortedList
               }
               return []

          } catch (e) {
               console.error("getWorkIndex", e)
          }
     }

     const getWorkItem = async (id: string) => {
          try {
               const doc = await firestore.collection("works").doc(id).get()
               const work = doc.data()
               if (doc.exists) {
                    return work
               }
               return
          } catch (e) {
               console.error("getWorkItem", e)
          }
     }

     return (

          <>
               <ItemShow heroes={heroes} />
               <ItemArtist selectedArtist={selectedArtist} />
               <ItemFeeds labelTitle={'New Arrival'} labelLinkname={'View all artworks'} works={recentWorks} />
          </>
     );
}
