import { ItemFeeds, Navbar, ProfileComponent } from 'components'
import { firestore } from 'libs/firebase-sdk';
import { useDAppContext } from 'providers/dapp';
import { useState, useEffect } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { ICreator, IWork } from '../../types'

export default function Profile() {

    const creator = Object(useParams()).creator;

    const { account } = useDAppContext();
    const [user, setUser] = useState<ICreator | null>(null)
    const [works, setWorks] = useState<IWork[]>([])

    useEffect(() => {
        if (creator) {
            getUser()
            getWork()
        }
        return () => {
            setUser(null)
            setWorks([])
        }
   }, [creator])

   const getUser = () => {
        console.log(creator)
        firestore.collection('users').doc(creator || '').get().then((doc) => {
             if (doc.exists) {
                  setUser(doc.data() as ICreator)
             } else {
                  console.log("No such document!");
             }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

   const getWork = () => {
        let worksList: IWork[] = []
        firestore.collection('works').where("owner", "==", creator || '').get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                worksList.push(doc.data() as IWork)
            });
            setWorks(worksList)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    return (
        <>
            <div className="navbar-container">
                <ProfileComponent creator={user} />
            </div>

            <ItemFeeds labelTitle={'Created'} works={works} />
        </>

    )
}
